import { useNavigate } from "react-router";
import styles from "./App.module.scss";
import CamelotButton from "./components/CamelotButton";
import CardLista from "./components/CardLista";
import Header from "./components/Header";
import { arrListe } from "./models/lista";
import { View } from "./models/step_enum";
import { useState } from "react";
import ProceduraMobile from "./components/ProceduraMobile";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import expand from "./libs/assets/expandIcon.svg";

function App() {
  const navigate = useNavigate();

  const [openProcedura, setOpenProcedura] = useState(false);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header currentView={View.candidates} />
      </div>

      {openProcedura ? (
        <div className={styles.body}>
          <ProceduraMobile onClose={() => setOpenProcedura(false)} />
        </div>
      ) : (
        <div className={styles.body}>
          <div className={styles._liste}>
            <div className={styles.__intestazione}>
              <p style={{ color: "#0069CA", fontWeight: 700 }}>
                LISTE E CANDIDATI
              </p>
              <a
                style={{ color: "#0069CA", fontWeight: 500 }}
                href="https://camelotsrlsb.s3.eu-west-1.amazonaws.com/FAQ+COMUNE+DI+NAPOLI.pdf"
                target="_blank"
              >
                FAQ
              </a>
            </div>

            <div className={styles.__wrapperDesktop}>
              {arrListe.map((e) => {
                return <CardLista key={e.listID} lista={e} />;
              })}
            </div>
            <div className={styles.__wrapperMobile}>
              {arrListe.map((e) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<img src={expand} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div className={styles.accordionPreview}>
                          <img
                            className={styles._listaMobileLogo}
                            src={`https://cdn.camelot.vote/napoli/${e.logo}.jpg`}
                            alt="logo"
                          />
                          <p>{e.nome}</p>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {e.candidati.map((e, index) => {
                        return (
                          <Typography>
                            {index + 1}. {e.cognome.toUpperCase()} {e.nome}
                          </Typography>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </div>

          <div className={styles._cta}>
            <CamelotButton
              text="Procedura di voto"
              secondary
              action={() => setOpenProcedura(true)}
            />
            {/* {Date.now() <
            1702641600000 
            && (
              <CamelotButton
                text="VOTA"
                action={() => {
                  navigate("/register");
                }}
              />
            )} */}
          </div>

          <div className={styles._procedura}>
            <p style={{ color: "#0069CA", fontWeight: 700 }}>
              PROCEDURA DI VOTO
            </p>
            <div className={styles.__greyWrapper}>
              {/* {dayjs().diff("2023-12-04") < 0 ? (
                <div className={styles.fase}>
                  <strong>Fase di pre-registrazione</strong>
                  <div className={styles.data}>
                    <p>DATA CHIUSURA</p>
                    <strong>4 Dicembre ore 11:00</strong>
                  </div>
                  <p>
                    Fino al 4 dicembre gli aventi diritto avranno la possibilità
                    di pre-registrarsi alla votazione, completando prima del
                    voto la procedura di identificazione. Coloro che si saranno
                    pre-registrati riceveranno via mail il link di voto il
                    giorno 11 dicembre.{" "}
                    <a
                      style={{ color: "#0069CA" }}
                      onClick={() => navigate("/register")}
                    >
                      Avvia la procedura di pre-registrazione.
                    </a>
                  </p>
                </div>
              ) : (
                <></>
              )} */}

              <div className={styles.fase}>
                <strong>Voto digitale</strong>
                <div className={styles.data}>
                  <p>DATA APERTURA</p>
                  <strong>11 Dicembre ore 13:00</strong>
                </div>
                <div className={styles.data}>
                  <p>DATA CHIUSURA</p>
                  <strong>15 Dicembre ore 13:00</strong>
                </div>
                <p>
                  {" "}
                  Dal 11 al 15 dicembre sarà possibile votare inserendo le
                  informazioni anagrafiche per verificare l’idoneità
                  dell’elettore a partecipare alle elezioni del Forum dei
                  Giovani del Comune di Napoli. Al termine della procedura verrà
                  inviato un link alla casella di posta elettronica personale
                  per accedere direttamente alla piattaforma di voto.
                </p>
              </div>

              <div className={styles.fase}>
                <strong>Voto in presenza </strong>
                <div className={styles.data}>
                  <p>DATA APERTURA</p>
                  <strong>16 Dicembre ore 9:00</strong>
                </div>
                <div className={styles.data}>
                  <p>DATA CHIUSURA</p>
                  <strong>16 Dicembre ore 13:00</strong>
                </div>
                <p>
                  Il 16 Dicembre, presso il seggio allestito a Palazzo San
                  Giacomo in Piazza Municipio, sarà possibile votare di persona
                  dalle ore 9 alle ore 13. Dopo l'identificazione dell'elettore
                  svolta dalla Commissione elettorale, si avrà accesso ad una
                  delle urne digitali disponibili per esprimere il proprio voto.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
