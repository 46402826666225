import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Register from "./pages/Register";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  // {
  //   path: "/register",
  //   element:Date.now() <
  //   1702641600000 ? <Register /> : null,
  // },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleReCaptchaProvider
    scriptProps={{ appendTo: "head" }}
    reCaptchaKey="6LdwsR0pAAAAANbqAPHRsV8B7hCgxN8Uz6Y1MNxz"
  >
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
